import React, {useState} from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
import {pb} from "../../api/pocketbaseApi";

export const Remove = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = React.useState<any>(null);
   const [test, setTest] = React.useState("");
  const onSend = async () => {
    try {
      await pb.collection('contactsPrattSW').create({text: email, email});
      setEmail('')
      setError('')
      setTest('Sending!')
    } catch (e) {
      setError('Not valid Email')
      console.log(e)
    }
  }
  return (
    <Container>
      <Title>Remove user data and account</Title>

      <Section>
        <Subtitle>INTRODUCTION</Subtitle>

        {error && <div style={{color: 'red', padding: 15, fontSize: 17}}>{error}</div>}
        <input style={{padding: 15, borderRadius: 15}} onChange={(e: any) => setEmail(e.target.value)} />

        {test ? <div style={{color: '#434343', padding: 15, fontSize: 17}}>{test}</div> :

        <button disabled={email.length <= 5} style={{padding: 15, borderRadius: 15}} onClick={() => onSend()}>
              Remove account
            </button>}

      </Section>








    </Container>
  )
}

const Container = styled.div`
  max-width: 1120px;
  margin: 50px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;
  flex: 1 0 auto;

  @media (min-width: ${screens.tablet}) {
    margin: 50px auto 0;
    width: 96%;
  }

  @media (min-width: ${screens.desktop}) {
    margin: 80px auto 0;
    gap: 80px;
  }
`
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  color: ${palette.text.primary};
  text-align: center;

  @media (min-width: ${screens.tablet}) {
    font-size: 2em;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 2.2em;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  color: ${palette.text.primary};
  margin: 10px 0;
  text-align: center;

  @media (min-width: ${screens.desktop}) {
    margin: 20px 0;
    font-size: 1.825em;
  }
`

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  color: ${palette.text.primary};
  margin: 10px 0;
  text-align: center;
`

const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 26px;
  color: ${palette.text.primary};
  opacity: .8;
  text-align: justify;
`

const Link = styled.a`
  color: ${palette.text.green};
  text-decoration: none;

  &:hover {
    filter: brightness(0.8);
    transition: 0.3s;
  }
`

const Descript = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 26px;
  color: ${palette.text.primary};
  opacity: .8;
  width: 100%;
`

const List = styled.ul`
  line-height: 32px;
  list-style-type: disc;
  margin: 10px 0;
  width: 80%;
  color: ${palette.text.primary};
  opacity: .8;
  font-weight: 500;

  @media (min-width: ${screens.tablet}) {
    width: 90%;
  }
`
