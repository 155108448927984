import React from 'react';
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";

import { Main, Privacy, Remove } from "./pages";
import { Footer, Header } from "./components";
import { palette, screens } from "./utils";
import BgWave from './assets/img/bg-wave.jpg'
import './localizations/i18';

function App() {
  return (
    <Container>
      <Header/>

      <Routes>
        <Route
          path={'/'}
          element={<Main/>}
        />
        <Route
          path={'/pp'}
          element={<Privacy/>}
        />

          <Route
              path={'/remove'}
              element={<Remove/>}
          />
        <Route
          path={'*'}
          element={<Main/>}
        />
      </Routes>

      <Footer/>
    </Container>
  );
}

export default App;

const Container = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  z-index: 10;

  &::before {
    content: "";
    height: 500px;
    width: 100%;
    background: ${palette.bg.tertiary};
    position: absolute;
    top: 0;
    z-index: -1;

    @media (min-width: ${screens.tablet}) {
      height: 412px;
    }

    @media (min-width: ${screens.desktop}) {
      height: 850px;
    }
  }

  &::after {
    content: "";
    background-image: url(${BgWave});
    background-size: cover;
    background-repeat: no-repeat;
    height: 228px;
    width: 100%;
    position: absolute;
    top: 500px;
    z-index: -1;

    @media (min-width: ${screens.tablet}) {
      background-position: left;
      top: 412px;
    }

    @media (min-width: ${screens.desktop}) {
      background-position: unset;
      top: 850px;
      height: 14vw;
    }
  }
`
