import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
import BgFlowers from '../../assets/img/bg-flowers.jpg'

import {Advantages, GetStarted} from "./components";
import i18next from "i18next";
import {useTranslation} from "react-i18next";


export const Main = () => {
    const { t } = useTranslation();


    const companyes = {
        one: {
            title: <Title>{t('title')} <GreenTitle>{t('title2')}</GreenTitle></Title>,
            GetStarted: true,
            Advantages: true,
            footer: <></>,
            startBtn: true
        },
    }


    let company = companyes.one;

  return (
    <Container>
      <Section>
        <TextBlock>
          {/*<Heading>Добро пожаловать в SnapDiet!</Heading>*/}
            {company.title}
          <Subtitle>{t('subtitle')}</Subtitle>

            {company.startBtn && <ButtonWrap>

                {/*<Button onClick={() => {*/}
                {/*    document.querySelector('#section1')?.scrollIntoView({*/}
                {/*        behavior: 'smooth'*/}
                {/*    });*/}
                {/*}}>Начать</Button>*/}

                <a

                    href={`https://apps.apple.com/${i18next.language === 'ru' ? 'ru' : 'us'}/app/snapdiet-app/id6498787212`} target='_blank' style={{background: '#55C65C',
                    borderRadius: 25,
                    color: '#fff',
                    fontWeight: '600',
                    fontSize: 19,
                    width:  230,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px 0'
                }}

                >{t('startBtnText')}</a>

                {/*<a style={{    position: 'absolute',*/}
                {/*    top: 10,*/}
                {/*    right: 10,}} href='https://apps.apple.com/ru/app/snapdiet-app/id6498787212' target='_blank'>*/}
                {/*    <AppStoreImg src={AppStore}/>*/}
                {/*</a>*/}
            </ButtonWrap>}
        </TextBlock>

          <Cover>
              <PhoneImg fetchPriority="high" src={`/assets/${i18next.language}/phone.png`} alt='phone'/>
              <DishImg fetchPriority="high" src='/assets/dishImg.png' alt='dish'/>
          <BiscuitImg fetchPriority="high"  src='/assets/biscuitImg.png' alt='biscuit'/>
        </Cover>
      </Section>

        {company.GetStarted && <GetStarted/>}
        {company.Advantages && <Advantages/>}
      {/*<Subscription/>*/}

      <Contact>
        <ContactHeading>
          <ContactSectionName id="section2">{t('contactSectionName')}</ContactSectionName>
            <ContactTitle style={{fontSize: 48, marginBottom: 10, maxWidth: 450, textAlign: 'center', lineHeight: '55px', fontWeight: 600}}>
                {t('contactTitle')}
            </ContactTitle>
            <ContactSubtitle style={{fontSize: 23, marginBottom: 16}}> {t('contactSubtitle')}</ContactSubtitle>
             {/*<ContactSubtitle><b>с образованием</b> врача-диетолога</ContactSubtitle>*/}
        </ContactHeading>

        <Form>
        {/*    {!isSend &&*/}
        {/*        <Input*/}
        {/*            value={name}*/}
        {/*            placeholder='Ваш телефон'*/}
        {/*            onChange={(e) => setName(e.target.value)}*/}
        {/*        />}*/}

          <FormButtonWrap>
              <ButtonA
                  href={`https://apps.apple.com/${i18next.language === 'ru' ? 'ru' : 'us'}/app/snapdiet-app/id6498787212`}

                  target='_blank'>{t('formButtonText')}</ButtonA></FormButtonWrap>
        </Form>
      </Contact>
    </Container>
  )
}


const ButtonA = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  border: none;
  border-radius: 40px;
  background: ${palette.bg.secondary};
  color: #fff !important;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 27px;

  &:hover {
    transition: .3s;
    filter: brightness(0.9);
      opacity: 1;
  }
`


const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  gap: 32px;
  padding-bottom: 40px;

  @media (min-width: ${screens.tablet}) {
    padding-bottom: 50px;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 0;
    gap: 78px;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 0;
  width: 342px;

  @media (min-width: ${screens.tablet}) {
    padding: 48px 0 0;
  }

  @media (min-width: ${screens.desktop}) {
    padding-top: 76px;
  }
`

const Heading = styled.p`
  color: ${palette.text.tertiary};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18.23px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: .1em;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 12px;
  }

  @media (min-width: ${screens.desktop}) {
    margin-bottom: 16px;
  }
`

const Title = styled.h1`
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 2.25em;
  line-height: 40px;
  text-align: center;
  margin-bottom: 16px;
  letter-spacing: -0.018em;

  @media (min-width: ${screens.tablet}) {
    font-size: 3.125em;
    line-height: 60px;
    width: 400px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 4em;
    line-height: 76px;
    letter-spacing: -0.024em;
    width: 920px;
    margin-bottom: 24px;
  }
`

const GreenTitle = styled.span`
  color: ${palette.text.tertiary};
`

const Subtitle = styled.p`
  color: ${palette.text.primary};
  font-size: 1.125em;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: -0.01em;

  @media (min-width: ${screens.tablet}) {
    width: 474px;
    line-height: 26px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 1.25em;
    line-height: 28px;
    margin-bottom: 32px;
    width: 900px;
  }
`

const ButtonWrap = styled.div`
    width: 225px;
    flex-direction: column;
    gap: 15px;
    display: flex;
    justify-content: center;

    @media (min-width: ${screens.tablet}) {
        width: 495px;
        flex-direction: row;

    }

    @media (min-width: ${screens.desktop}) {
        width: 495px;
        flex-direction: row;

    }
    
`

const Cover = styled.div`
  position: relative;
`

const PhoneImg = styled.img`
  width: 476px;
  animation: moveUpDown 3s infinite alternate ease-out;

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-30px);
    }
  }

  @media (min-width: ${screens.desktop}) {
    width: 840px;
    margin-left: -50px;

    @keyframes moveUpDown {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-50px);
      }
    }
  }
`

const BiscuitImg = styled.img`
  width: 80px;
  position: absolute;
  right: -40px;
  bottom: 116px;
  display: none;

  @media (min-width: ${screens.tablet}) {
    display: block;
  }

  @media (min-width: ${screens.desktop}) {
    width: 168px;
    bottom: 204px;
    right: -102px;
  }
`

const DishImg = styled.img`
  width: 90px;
  position: absolute;
  left: -66px;
  top: 68px;
  display: none;

  @media (min-width: ${screens.tablet}) {
    display: block;
  }

  @media (min-width: ${screens.desktop}) {
    width: 138px;
    top: 120px;
    left: -130px;
  }
`

const Contact = styled.div`
  background-image: url(${BgFlowers});
  padding: 80px 16px;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 20px;


  @media (min-width: ${screens.tablet}) {
    padding: 80px 110px 100px;
    margin: 0 24px;
    border-radius: 24px;
    background-size: cover;
    gap: 24px;
  }

  @media (min-width: ${screens.desktop}) {
    margin: 0 40px;
    padding: 100px 0 132px;
  }
`

const ContactHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactSectionName = styled(Heading)`
  color: ${palette.text.secondary};
`

const ContactTitle = styled.h2`
  color: ${palette.text.secondary};
  font-weight: 700;
  font-size: 2em;
  line-height: 29px;
  letter-spacing: -0.018em;
  margin-bottom: 16px;

  @media (min-width: ${screens.tablet}) {
    font-size: 2.25em;
    line-height: 48px;
    margin-bottom: 12px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 60px;
  }
`

const ContactSubtitle = styled.p`
  color: ${palette.text.secondary};
  line-height: 24px;
  text-align: center;

  @media (min-width: ${screens.tablet}) {
    width: 400px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 1.125em;
    line-height: 28px;
    letter-spacing: -0.2px;
    width: 450px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${screens.tablet}) {
    width: 500px;
    margin: 0 auto;
  }
`

const FormButtonWrap = styled.div`
  width: 100%;

  @media (min-width: ${screens.tablet}) {
    margin-top: 4px;
  }
`
