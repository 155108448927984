import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import en from "./en.json";
import ru from "./ru.json";

const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    },
};

i18n.use(initReactI18next)
    .use(detector)
    .init({
        supportedLngs: ['ru', 'en'],
        debug: true,
        resources,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });


export const t = i18n.t;
